.App {
  text-align: center;
}

.profile-photo {
  height: 40vmin;
  pointer-events: none;
  border-radius: 75%;
}

.profile-name {
  font-size: calc(20px + 2vmin);
  font-weight: bolder;
}

.profile-description {
  font-size: calc(10px + 0.5vmin);
  width: 25vw;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.social-links {
  flex-direction: row;
  display: flex;
}

.social-link {
  display: block;
  padding: 15px;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
} */

#tsparticles {
  position: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  z-index: -1;
}
